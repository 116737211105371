import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { Header } from '../components/global/Header';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Grid } from '@mui/material';

export const Login = ({ username, setUsername, password, setPassword }) => {
    const navigate = useNavigate();
    const wsUrl = "compras.clasol.com";
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post(`https://${wsUrl}/WS/login.php`, {
                username: username,
                password: password,
            });

            if (response.data.success) {
                // Guarda el token en una cookie con una fecha de expiración
                const tokenExpiryDate = new Date();
                tokenExpiryDate.setTime(tokenExpiryDate.getTime() + (12 * 60 * 60 * 1000)); // 12 horas
                document.cookie = `PHPSESSID=${response.data.token}; path=/; expires=${tokenExpiryDate.toUTCString()};`;

                // Guarda otros datos en el almacenamiento local
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.DesRepresentante);
                setPassword('');
                setUsername('');

                Swal.fire({
                    icon: "success",
                    title: `Bienvenido/a \n${username}`,
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/');
            } else {
                Swal.fire({
                    icon: "error",
                    title: `Error de inicio de sesión: \n${response.data.message}`,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    return (
        <div>
            <Header />
            {/* <Grid className='flex-container'> */}
            <Grid container xs={12} direction="column" justifyContent="center" alignItems="center" fontWeight={500} >

                <form>
                    <Grid container xs={7} direction="column" justifyContent="center" alignItems="center" className='login-card'>
                        <div className='login-icon'>
                            <LockPersonIcon sx={{ fontSize: 100 }} />
                        </div>
                        <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" className='login-element'>
                            <Grid container xs={2} direction="row" justifyContent="center" alignItems="center">
                                <PersonIcon sx={{ fontSize: 50 }} style={{ color: '#282c34' }} />&nbsp;
                            </Grid>
                            <Grid container xs={7} direction="row" justifyContent="center" alignItems="center">
                                <TextField placeholder='Usuario' id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" className='login-element'>
                            <Grid container xs={2} direction="row" justifyContent="center" alignItems="center">
                                <VpnKeyIcon sx={{ fontSize: 50 }} style={{ color: '#282c34' }} />&nbsp;
                            </Grid>
                            <Grid container xs={7} direction="row" justifyContent="center" alignItems="center">

                                <OutlinedInput
                                    placeholder='password'
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                        <div className='login-element'>
                            <div className="return-button" onClick={handleLogin}>Iniciar sesión</div>
                        </div>
                    </Grid>
                </form>
            </Grid>
        </div>
    );
};
