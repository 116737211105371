import React, { useEffect, useState } from 'react'
import { Header } from '../components/global/Header'
import axios from 'axios';
import { CompraCard } from '../components/compras/CompraCard';
import { useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { getCookie } from '../components/utils/cookies';
import { getopcinesCompras } from '../services/apis';
import { Checkbox, Grid } from '@mui/material';

export const VerFirmadas = () => {

	//util
	const [compras, setCompras] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const wsUrl = "compras.clasol.com";
	const Navigate = useNavigate();
	const code = localStorage.getItem('code');
	const [browser, setBrowser] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const token = getCookie('PHPSESSID');
	const [opcionesFirmadas, setOpcionesMenu] = useState([]);
	const [comrpasFiltradas, setComprasFiltradas] = useState([]);

	useEffect(() => {
		firstLoad();
		cargarOpcionesFirmadas();
	}, [])
	const checkOptions = {
		switch(option) {
			switch (option) {
				case 'Importado':
					console.log('Option 1 selected');
					changeFilterStatus("Importado");

					break;
				case 'Aica':
					console.log('Option 2 selected');
					changeFilterStatus("Aica");

					break;
				case 'option3':
					console.log('Option 3 selected');
					break;
				default:
					console.log('Unknown option');
			}
		}
	};

	const changeFilterStatus = (opcion) => {
		let temp = [...opcionesFirmadas]
		temp = temp.map((item) => {
			if (item.option === opcion) {  // Check if the current item matches the option
				return {
					...item,
					checked: item.checked === "true" ? "false" : "true"  // Toggle the checked value
				};
			}
			return item;  // If not matching, return the item unchanged
		});
		setOpcionesMenu(temp);
		filtrar(temp)
	}

	const filtrar = (data) => {
		console.log(data);
		let filtros = data.filter(item => item.checked == "true");
		let opciones = filtros.map(item => item.option);
		let comprasArr = [];

		opciones.forEach((opcion) => {
			// Filtra las compras que cumplen la condición
			let comprasFiltradas = compras.filter((compra) => compra[opcion] == 1);

			// Añade las compras filtradas a comprasArr, evitando duplicados
			
			if (comprasFiltradas.length > 0) {
				comprasFiltradas.forEach((compra) => {
					// Verifica si la compra ya está en comprasArr
					if (!comprasArr.some(existingCompra => JSON.stringify(existingCompra) === JSON.stringify(compra))) {
						comprasArr.push(compra);
					}
				});
			}
		});

		console.log("Compras sin duplicados:", comprasArr);
		setComprasFiltradas(comprasArr)
		console.log("Opciones:", opciones, "Compras:", compras);
	};

	//buscador
	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		setSearchTerm(searchTerm);

		const filteredData = browser.filter((item) =>
			searchTerm
				.split(/\s+/)
				.every((word) => item.lista.toLowerCase().includes(word))
		);

		setCompras(filteredData);
	};
	const listar = (list) => {
		const newList = list.map((element) => ({
			...element,
			lista: `${element.IDCompra} ${element.DesRepresentante} ${element.Nombre} ${element.Cantidad} kg ${element.DesVariedad}  ${element.Fecha} ${element.codigoPapel} ${element.NIF}`,
		}));

		console.log(newList);
		setBrowser(newList);
		setCompras(newList);
		setComprasFiltradas(newList);
		setLoaded(true);
	};
	//buscador

	const firstLoad = () => {
		//cargar compras
		axios.get('https://' + wsUrl + '/WS/compras.php?&firmada=1?&token=' + token)
			.then(function (response) {
				listar(response.data);
			})
			.catch(function (error) {
				console.error('Error en la solicitud:', error);
			})
			.finally(function () {
			})
	}

	const cargarOpcionesFirmadas = async () => {
		try {
			const response = await getopcinesCompras();
			setOpcionesMenu(response.data)
			console.log("hola holita", response)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<Header />
			<div className='flex-left'>
				<div className='return-button' onClick={() => Navigate("/")}>
					<KeyboardReturnIcon />
					&nbsp;Volver
				</div>
			</div>
			<div className='flex-container'>
				<div className='header-compras-list'>
					Listado de compras Firmadas
				</div>
			</div>
			<div className='flex-container'>
				<div className='browser'>
					<div className='browser-card'>
						<SearchIcon sx={{ fontSize: 40 }} /><TextField value={searchTerm} onChange={handleSearch} fullWidth label="Buscar" id="fullWidth" />
					</div>
				</div>
			</div>
			{(loaded === false) && (
				<div className='flex-container spinner'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}
			{loaded === true && (
				<div className="compras-list-container">
					{opcionesFirmadas.length > 0 && (
						opcionesFirmadas.map((opcion, index) => (
							<Grid container xs={2} className='filtros'>
								<Checkbox
									checked={opcion.checked == "true"}  // Simplified the checked logic
									name='opcion1'
									onChange={() => checkOptions.switch(opcion.option)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								{opcion.nombre_opcion}
							</Grid>
						))
					)}
					{comrpasFiltradas.length >= 1 ? (
						comrpasFiltradas.map((compra, index) => (
							<div key={index} className="compras-list-card">
								<CompraCard
									compra={compra}
									firmada={'Ver Contrato'}
									setCompras={setCompras}
									compras={compras}
								/>
							</div>
						))
					) : (<>
						<div className="emptey-alert">
							Todavia No hay compras por firmar....
						</div>
						<div className='return-button' onClick={() => Navigate("/")}>
							<KeyboardReturnIcon />
							Vovler
						</div>
					</>
					)}
				</div>
			)}
		</div>
	)
}
