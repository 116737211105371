import React, { useEffect, useState } from 'react';
import { Page, Text, Image, Document, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import logo from '../../media/logo.png';
import { useParams } from 'react-router-dom';

export const PdfCompra = ({compra}) => {

	//global 

	//value
	const [first, setfirst] = useState("second");
	useEffect(() => {
	 	setfirst(compra)
	}, [])
	
	return (
		<Document>
			<Page size="A4">
				<View style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					
				}}>
					<View style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "white",
						maxWidth: "90%",
						minWidth: "90%",
						
					}}> 
						<Text style={{fontSize: "20"}}> CONTRATO COMPRAVENTA {first ? first : ".."}</Text>
					</View>					
				</View>
			</Page>		
		</Document>
  )
}
