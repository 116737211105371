import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://compras.clasol.com/WS/'
//opciones menu
export const getopcinesMenu = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesmenu.php?', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response)
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getopcinesCompras = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionescompras.php?', {
            params: { token: getCookie('PHPSESSID') },
        });
        console.log("ss",response)

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};