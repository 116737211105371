import React, { useEffect, useState } from 'react';
import { Header } from '../components/global/Header';

import TextField from '@mui/material/TextField';
import { Autocomplete, Checkbox, Grid, MenuItem, Select } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs';
import axios from 'axios';
import { AltaProveedor } from '../components/AltaProveedor';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { ProveedorCard } from '../components/compras/ProveedorCard';
import { getCookie } from '../components/utils/cookies';




export const EditarCompras = () => {

    //global
    const Navigate = useNavigate();
    const wsUrl = "compras.clasol.com";
    const token = getCookie('PHPSESSID');


    //util
    const [loaded, setLoaded] = useState(false);
    const [representantes, setRepresentantes] = useState([]);

    const [variedades, setVariedades] = useState([]);
    const [variedad, setVariedad] = useState("");
    const [opVariedades, setOpVariedades] = useState([]);

    const [modalidad, setModalidad] = useState([]);
    const [ejercicio, setEjercicio] = useState([]);
    const [ejercicios, setEjercicios] = useState([]);

    const [gurposPortes, setGruposPortes] = useState([]);
    const [opGrupoPortes, setopGrupoPortes] = useState([]);
    const [grupoPorte, setGrupoPorte] = useState("");

    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState("");
    const [opProveedores, setOpProveedores] = useState([]);
    const [arrProveeCompra, setArrProveeCompra] = useState([]);
    const [tmpProve, setTmpProve] = useState("");

    const [proveForm, setProveFrom] = useState(false);
    const param = useParams();

    const [opProvloaded, setOpProvLoaded] = useState(false);
    const [anticipado, setAnticipado] = useState(false);
    const [multiProveedor, setMultiProveedor] = useState(false);
    const [dissSldiers, setDissSliders] = useState(false);
    const [sinComision, setSinComision] = useState(false);

    //values


    //compra obj setup
    const [compra, setCompra] = useState({
        'IDCompra': null,
        'Ejercicio': null,
        'CodigoCompra': null,
        'Fecha': null,
        'Proveedor': null,
        'Referencia': null,
        'Comprador': "23",
        'Fecha1Recoleccion': null,
        'Representante': null,
        'Variedad': null,
        'Modalidad': null,
        'Cantidad': null,
        'Precio': null,
        'Comisiones': null,
        'Observaciones': null,
        'Anticipo': null,
        'PrecioRecolectado': null,
        'grupoPortes': null,
        'ivaPrecio': false
    });

    useEffect(() => {
        firstData();
    }, []);

    useEffect(() => {
        cargarDatosCompra();

    }, [(opProvloaded === true)]);
    useEffect(() => {
        if (anticipado !== true) {
            compra.Anticipo = null;
        }
    }, [anticipado])

    const handleChange = (event) => {
        if (event.target.name === "Precio") {
            if (event.target.value > 0) {
                if ((event.target.value > 2 && compra.Modalidad !== '02') || event.target.value > 100000) {
                    Swal.fire({
                        title: 'Estas seguro que el precio es ' + event.target.value + '€ por KG?',
                        text: "Este precio esta fuera del rango habitual de precios!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })
                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }

        } else if (event.target.name === "Cantidad") {
            const inputValue = parseFloat(event.target.value);
            // Limita a 2 decimales usando toFixed y luego convierte de nuevo a número
            const roundedValue = parseFloat(inputValue.toFixed(0));
            const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
            setCompra(nuevaCompra);
        } else if (event.target.name === "Anticipo") {
            const inputValue = parseFloat(event.target.value);
            // Limita a 2 decimales usando toFixed y luego convierte de nuevo a número
            const roundedValue = parseFloat(inputValue.toFixed(0));
            const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
            setCompra(nuevaCompra);
            if (roundedValue > 100000) {
                Swal.fire({
                    title: 'Estas seguro que el Anticipo es ' + roundedValue + '€ ?',
                    text: "Este precio esta fuera del rango habitual de anticipo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ff8d22',
                    cancelButtonColor: '#ff8d22',
                    confirmButtonText: 'Sí, estoy seguro!',
                    cancelButtonText: 'No, no estoy seguro'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: 'Guardado!',
                            text: 'Se ha guardado correctamente',
                            icon: 'success',
                            confirmButtonColor: '#ff8d22'
                        }
                        )
                    } else {
                        const nuevaCompra = { ...compra, [event.target.name]: 0 };
                        setCompra(nuevaCompra);
                    }
                })

            }
        } else if (event.target.name === "Comisiones") {
            if (event.target.value > 0) {
                if (event.target.value > 3) {
                    Swal.fire({
                        title: 'Estas seguro que la comision es de un ' + event.target.value + '% ?',
                        text: "Este procentaje de comision está fuera del rango habitual!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })
                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }
        } else if (event.target.name === "Modalidad") {
            const nuevaCompra = {
                ...compra,
                [event.target.name]: event.target.value,
                Precio: 0,
                Comisiones: 0
            };

            setCompra(nuevaCompra);

            //modalidad A liquidar
        } else if (event.target.name === "PrecioRecolectado") {
            if (event.target.value > 0) {
                if (event.target.value > 3) {
                    Swal.fire({
                        title: 'Estas seguro que la comision es de un ' + event.target.value + '% ?',
                        text: "Este procentaje de comision está fuera del rango habitual!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })

                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }

        }
        // else if (event.target.name === "Fecha") {
        //     console.log(event.target.name)
        //     let nuevaCompra = { ...compra, [event.target.name]: event.target.value };
        //     nuevaCompra.Fecha1Recoleccion = event.target.value;
        //     setCompra(nuevaCompra);

        // }
        else if (event.target.name === "CodigoCompra") {
            if (event.target.value <= 999999) {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: 999999 };
                setCompra(nuevaCompra);
            }
        }
        else {
            const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
            setCompra(nuevaCompra);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        finalizarCompra();
    };

    const firstData = () => {
        //representantes
        axios.get('https://' + wsUrl + '/WS/representante.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setRepresentantes(response.data);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });

        //variedades
        axios.get('https://' + wsUrl + '/WS/variedades.php')
            .then(function (response) {
                setVariedades(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.DesVariedad,
                    value: item.CodVariedad
                }));
                setOpVariedades(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
        //grupo portes
        axios.get('https://' + wsUrl + '/WS/grupoPortes.php')
            .then(function (response) {
                console.log(response.data)
                setGruposPortes(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.descripcion,
                    value: item.codigoPorte
                }));
                setopGrupoPortes(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
        //modalidades
        axios.get('https://' + wsUrl + '/WS/modalidad.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setModalidad(response.data);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });

        //ejercicio
        axios.get('https://' + wsUrl + '/WS/ejercicio.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setEjercicios(response.data)
                const ejercicio = response.data.find((element) => element.Activo == 1);
                setEjercicio(ejercicio);

                const nuevaCompra = { ...compra, "Ejercicio": ejercicio.Ejercicio };
                setCompra(nuevaCompra);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })

        //proveedor
        axios.get('https://' + wsUrl + '/WS/proveedor.php?contrasena=sakmascna0o898ajhdasdj232')
            .then(function (response) {
                // manejar respuesta exitosa
                setProveedores(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.Nombre + " " + item.NIF,
                    value: item.CodTercero
                }));
                setOpProveedores(newTemp);
                setOpProvLoaded(true);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })



    }

    const cargarDatosCompra = () => {
        //    //datos de la compra especifica
        axios.get('https://' + wsUrl + '/WS/compras.php?id=' + param.id+ '&firmada=0?&token='+token)
            .then(function (response) {
                console.log(response.data)
                añadirProvPrincipalArrProv();

                //           // manejar respuesta exitosa
                const nuevaCompra = {
                    ...compra,
                    IDCompra: response.data[0].IDCompra,
                    Cantidad: response.data[0].Cantidad,
                    Referencia: response.data[0].Referencia,
                    Fecha: response.data[0].Fecha,
                    Fecha1Recoleccion: response.data[0].Fecha1Recoleccion,
                    Precio: response.data[0].Precio,
                    Comisiones: response.data[0].Comisiones,
                    CodigoCompra: response.data[0].CodigoCompra,
                    Modalidad: response.data[0].Modalidad,
                    Representante: response.data[0].Representante,
                    Ejercicio: response.data[0].Ejercicio,
                    Observaciones: response.data[0].Observaciones,
                    Anticipo: response.data[0].Anticipo,
                    PrecioRecolectado: response.data[0].PrecioRecolectado,
                    ivaPrecio: (response.data[0].sinIVA == '1' ? true : false)

                };

                setCompra(nuevaCompra);
                setProveedor(opProveedores.find(op => op.value === response.data[0].Proveedor[0].CodTercero));
                setVariedad(opVariedades.find(op => op.value === response.data[0].Variedad));
                setGrupoPorte(opGrupoPortes.find(op => op.value == response.data[0].grupoPortes));
                const addAllProvs = response.data[0].Proveedor;
                const newProveeCompra = addAllProvs.map((allprov, index) => {
                    let temp = opProveedores.find((opProv) => allprov.CodTercero === opProv.value);
                    const porcentaje = parseFloat(allprov.PorcenPropi, 10);
                    const updatedTemp = { ...temp, porcentaje }; // Crear un nuevo objeto con porcentaje
                    return updatedTemp;
                });

                if (compra.Comisiones == 0) {
                    setSinComision(true);
                }

                // Filtra los elementos en newProveeCompra que tengan la propiedad 'label'
                const filteredNewProveeCompra = newProveeCompra.filter(item => item.label);

                const updatedArrProveeCompra = [...arrProveeCompra, ...filteredNewProveeCompra];
                setArrProveeCompra(updatedArrProveeCompra);
                if (updatedArrProveeCompra) {
                    setMultiProveedor(true);
                    setDissSliders(true);
                }
                setLoaded(true);
                //si hay pago anticipado que salga desplegado el campo
                if (response.data[0].Anticipo !== null) {
                    setAnticipado(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            })
    }



    const finalizarCompra = () => {

        let nuevaCompra = { default: null };

        if (proveedor) {
            if (multiProveedor) {
                nuevaCompra.Proveedor = []; // Inicializa como un array vacío
                arrProveeCompra.map((proveedor, index) => {
                    var proveedorData = proveedores.find((prov) => proveedor.value === prov.CodTercero);
                    proveedorData.porcentaje = proveedor.porcentaje;
                    let tmp = [...nuevaCompra.Proveedor];
                    tmp.push(proveedorData);
                    nuevaCompra = { ...compra, Proveedor: tmp };
                });
            } else {
                nuevaCompra.Proveedor = []; // Inicializa como un array vacío
                var proveedorData = proveedores.find((prov) => prov.CodTercero === proveedor.value);
                proveedorData.porcentaje = 100;
                let tmp = [...nuevaCompra.Proveedor];
                tmp.push(proveedorData);
                nuevaCompra = { ...compra, Proveedor: tmp };
            }
        }
        if (variedad) {
            nuevaCompra.Variedad = variedad.value;
        }
        if (grupoPorte) {
            nuevaCompra.grupoPortes = grupoPorte.value;
        }

        const isNullInObject = Object.keys(nuevaCompra).every(key => {
            // Hacer una excepción para Observaciones
            if (sinComision) {
                if (key === 'Comisiones') return true;
            }
            if (key === 'Observaciones' || key === 'Anticipo' || key === 'ivaPrecio'|| key === 'CodigoCompra' || 'Fecha1Recoleccion') {
                return true;
            }
            if (compra.Modalidad === "01") {
                if (key === 'Precio') return true;
            }
            if (compra.Modalidad !== "04") {
                if (key === 'PrecioRecolectado') return true;
            }
            return nuevaCompra[key] !== null && nuevaCompra[key] !== '' && nuevaCompra[key] != 0;
        });

        if (!isNullInObject) {
            console.log(nuevaCompra)
            Swal.fire({
                icon: 'error',
                title: 'Revisa que los campos esten correctamente',
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            axios.put('https://' + wsUrl + '/WS/comprasPost.php?id=' + param.id, nuevaCompra)
                .then(function (response) {
                    console.log(nuevaCompra)

                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Compra Editada correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        Navigate('/');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Revisa que los campos esten correctamente',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    }
                })
                .catch(function (error) {
                    console.error('Error al enviar la solicitud:', error);
                });
        }
    }
    //manejar el array de varios proveedores
    const añadirArrProveedores = (id) => {
        if (id) {
            const tmp = arrProveeCompra.filter(prov => prov.value !== id);
            setArrProveeCompra(tmp);
        } else {
            if (arrProveeCompra.find(prov => prov.value === tmpProve.value)) {
                Swal.fire({
                    icon: 'error',
                    title: 'No puedes añadir un Proveedor que ya participa en esta compra',
                    showConfirmButton: false,
                    timer: 2500
                })
            } else if (tmpProve) {
                var tmpTmpProve = tmpProve;
                tmpTmpProve = { ...tmpTmpProve, porcentaje: 0 };
                var tmp = [...arrProveeCompra, tmpTmpProve];
                setArrProveeCompra(tmp);
            }
        }
    }
    const añadirProvPrincipalArrProv = () => {
        var tmpTmpProve = proveedor;
        tmpTmpProve = { ...tmpTmpProve, porcentaje: 0 };
        var tmp = [...arrProveeCompra, tmpTmpProve];
        setArrProveeCompra(tmp);
    }
    const prepararArrPRoveeMultiple = () => {
        let totalPorcentajes = 0;
        arrProveeCompra.map((proveedor, index) => {
            totalPorcentajes += proveedor.porcentaje;
        })
        const badValuePorcen = arrProveeCompra.find(proveedorAux => proveedorAux.porcentaje < 1);
        if (badValuePorcen) {
            Swal.fire({
                icon: 'error',
                title: 'No puedes asignar menos de 1% a un proveedor',
                showConfirmButton: false,
                timer: 2500
            });
        } else {
            if (totalPorcentajes !== 100) {
                Swal.fire({
                    icon: 'error',
                    title: 'La suma de los porcentajes debe dar 100% y actualmente es ' + totalPorcentajes + '%',
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                setDissSliders(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Los porcentajes se han guardado correctamente',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        }
    }

    return (
        <div>
            <Header />
            <div className='flex-left'>
                <div className='return-button' onClick={() => Navigate("/")}>
                    <KeyboardReturnIcon />
                    &nbsp;Cancelar
                </div>
            </div>
            <div className='flex-container'>
                {(loaded === false) && (
                    <div className='flex-container spinner'>
                        <div className='loading-spinner'>
                        </div>
                    </div>
                )}
                {(loaded === true) && (

                    <div className="card-view">
                        <div className='card-view-title'>
                            Editar Compra
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='card-view-body'>
                                {(proveForm !== true) && (
                                    <><div className='card-view-element'>
                                        Modalidad:&nbsp;
                                        <Select
                                            className={compra.Modalidad ? "text-field" : 'text-field-empty'}
                                            value={compra.Modalidad || ''}
                                            name={"Modalidad"}
                                            onChange={handleChange}
                                            defaultValue=""
                                        >
                                            {modalidad.map((mod, key) => (
                                                <MenuItem
                                                    key={mod.CodModalidad}
                                                    value={mod.CodModalidad}
                                                    onChange={handleChange}
                                                >
                                                    {mod.DesModalidad}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                        <div className='card-view-element'>
                                            Campaña:&nbsp;
                                            <Select
                                                className={compra.Ejercicio ? "text-field" : 'text-field-empty'}
                                                value={compra.Ejercicio || ''}
                                                name={"Ejercicio"}
                                                onChange={handleChange}
                                                defaultValue=""
                                            >
                                                {ejercicios.map((ejercicio, key) => (
                                                    <MenuItem
                                                        key={ejercicio.Ejercicio}
                                                        value={ejercicio.Ejercicio}
                                                        onChange={handleChange}
                                                    >
                                                        {ejercicio.Descripcion}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {/* <div className='card-view-ejercicio'>
                                            Ejercicio:&nbsp;{ejercicio.Ejercicio}
                                        </div> */}
                                        <div className='card-view-element'>
                                            Código Compra Papel:&nbsp;
                                            <TextField
                                                required
                                                id="CodigoCompra"
                                                className="text-field"
                                                name={"CodigoCompra"}
                                                value={compra.CodigoCompra || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='card-view-element'>
                                            Fecha:&nbsp;
                                            <div className="dateInputContainer">
                                                <input
                                                    type="date"
                                                    id="dateInput"
                                                    name="Fecha"
                                                    className={compra.Fecha ? "inputDateInput" : 'inputDateInput-empty'}
                                                    required
                                                    value={compra.Fecha || ''}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='card-view-element'>
                                            {multiProveedor ? 'Proveedor principal: ' : 'Proveedor: '}
                                            <div className='add-button' onClick={() => setProveFrom(true)}>
                                                <AddCircleOutlineIcon fontSize="large" />
                                            </div>
                                            <Autocomplete
                                                className={proveedor ? "inputDateInput" : 'inputDateInput-empty'}
                                                disablePortal
                                                id="combo-box-demo"
                                                name="Proveedor"
                                                disabled={multiProveedor ? true : false}
                                                options={opProveedores || ''}
                                                sx={{ width: 300 }}
                                                value={proveedor || ""}
                                                onChange={(event, newValue) => {
                                                    setProveedor(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {(multiProveedor !== true) && (
                                                <div className={proveedor ? 'add-proveedor-button' : 'diss-proveedor-button'} onClick={proveedor ? () => { setMultiProveedor(true); añadirProvPrincipalArrProv() } : null}>
                                                    Añadir más proveedores a esta compra
                                                </div>
                                            )}
                                        </div>
                                        {(multiProveedor === true) && (

                                            <div className='card-view-form'>
                                                {(dissSldiers !== true) && (
                                                    <>
                                                        Busca y añade los demás proveedores que participen en esta compra.
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={opProveedores || ""}
                                                            sx={{ width: 300 }}
                                                            onChange={(event, newValue) => {
                                                                setTmpProve(newValue);
                                                            }}
                                                            renderInput={(arrProveeCompra) => <TextField {...arrProveeCompra} />}
                                                        />
                                                        <div className={tmpProve ? 'add-proveedor-button' : 'diss-proveedor-button'} onClick={tmpProve ? () => añadirArrProveedores() : null}>
                                                            Añadir
                                                        </div>
                                                        <div className='add-proveedor-button' onClick={() => { setMultiProveedor(false); setArrProveeCompra([]); setTmpProve("") }}>
                                                            Cancelar
                                                        </div>
                                                    </>
                                                )}
                                                Proveedores de la compra:
                                                {arrProveeCompra.length >= 1 && (
                                                    <div>
                                                        {arrProveeCompra.map((prov, index) => (
                                                            <>
                                                                <ProveedorCard key={prov.value}
                                                                    setArrProveeCompra={setArrProveeCompra}
                                                                    arrProveeCompra={arrProveeCompra}
                                                                    tmpProve={tmpProve}
                                                                    prov={prov}
                                                                    idProveedorPrincipal={proveedor.value}
                                                                    dissSldiers={dissSldiers}
                                                                />
                                                            </>))}
                                                    </div>
                                                )}
                                                {(dissSldiers !== true) && (
                                                    <div className='firmar-button' onClick={() => prepararArrPRoveeMultiple()}>
                                                        Confirmar porcentajes de la compra
                                                    </div>
                                                )}
                                                {(dissSldiers === true) && (
                                                    <div className='firmar-button' onClick={() => setDissSliders(false)}>
                                                        Editar porcentajes
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                {(proveForm === true) && (
                                    <div className='card-view-form'>
                                        Dar de alta un Proveedor:&nbsp;
                                        <AltaProveedor
                                            setProveFrom={setProveFrom}
                                            setProveedores={setProveedores}
                                            proveedores={proveedores}
                                            opProveedores={opProveedores}
                                            setOpProveedores={setOpProveedores}
                                        />
                                    </div>
                                )}
                                {(proveForm !== true) && (
                                    <>
                                        <div className='card-view-element'>
                                            {/* partida se guarda como referencia por sage */}
                                            Partida:&nbsp;
                                            <TextField
                                                className={compra.Referencia ? "text-field" : 'text-field-empty'}
                                                required
                                                id="codigo"
                                                name={"Referencia"}
                                                value={compra.Referencia || ''}
                                                onChange={handleChange}
                                            />
                                            Grupo Portes:&nbsp;
                                            <Autocomplete
                                                className={grupoPorte ? "inputDateInput" : 'inputDateInput-empty'}
                                                disablePortal
                                                id="combo-box-demo"
                                                name={"grupoPortes"}
                                                options={opGrupoPortes}
                                                sx={{ width: 300 }}
                                                value={grupoPorte || ""}
                                                onChange={(event, newValue) => {
                                                    setGrupoPorte(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                                            />
                                        </div>
                                        <div className='card-view-element'>
                                            Representante:&nbsp;
                                            <Select
                                                className={compra.Representante ? "text-field" : 'text-field-empty'}
                                                value={compra.Representante || ''}
                                                name={"Representante"}
                                                onChange={handleChange}
                                                defaultValue=""
                                            // onChange={handleChange}
                                            >
                                                {representantes.map((representante, key) => (
                                                    <MenuItem
                                                        key={representante.CodReferencia}
                                                        value={representante.CodReferencia}
                                                        onChange={handleChange}
                                                    >
                                                        {representante.DesRepresentante}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='card-view-element'>
                                            Fecha Límite:&nbsp;
                                            <div className="dateInputContainer">
                                                <input
                                                    type="date"
                                                    id="dateInput"
                                                    name="Fecha1Recoleccion"
                                                    className="inputDateInput" 
                                                    value={compra.Fecha1Recoleccion} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='card-view-element'>
                                            Variedad:&nbsp;
                                            <Autocomplete
                                                className={variedad ? "inputDateInput" : 'inputDateInput-empty'}
                                                disablePortal
                                                uncontrolled
                                                id="combo-box-demo"
                                                name="Variedad"
                                                options={opVariedades}
                                                sx={{ width: 300 }}
                                                value={variedad || ""}
                                                onChange={(event, newValue) => {
                                                    setVariedad(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                        <div className='card-view-element'>
                                            Cantidad:&nbsp;
                                            <TextField
                                                className={compra.Cantidad ? "text-field" : 'text-field-empty'}
                                                required
                                                id="codigo"
                                                type='integer'
                                                name={"Cantidad"}
                                                value={compra.Cantidad || ''}
                                                onChange={handleChange}
                                            />
                                            KG
                                        </div>
                                        {(compra.Modalidad !== '01') && (
                                            <div className='card-view-element'>
                                                IVA&nbsp;
                                                <Checkbox
                                                    value="checkIva"

                                                    inputProps={{
                                                        'aria-label': 'Checkbox A',
                                                    }}
                                                    checked={!compra.ivaPrecio}

                                                    onChange={() => {
                                                        if (compra.ivaPrecio) {
                                                            setCompra(prevCompra => ({ ...prevCompra, ivaPrecio: false }));
                                                        } else {
                                                            setCompra(prevCompra => ({ ...prevCompra, ivaPrecio: true }));
                                                        }
                                                    }}
                                                />
                                                {((compra.Modalidad === '04') ? 'Precio Fruta' : (compra.Modalidad !== '02') ? 'Precio por KG ' : 'Precio total ') + (compra.ivaPrecio ? 'sin IVA' : ' IVA inlcuido')}
                                                &nbsp;
                                                <TextField
                                                    required
                                                    id="codigo"
                                                    label="Obligatorio"
                                                    value={compra.Precio || ''}
                                                    className={compra.Precio ? "text-field" : 'text-field-empty'}
                                                    type="number"
                                                    step="0.00001" // Permitir hasta 6 decimales
                                                    name="Precio"
                                                    onChange={handleChange}
                                                />
                                                €
                                            </div>
                                        )}
                                        {(compra.Modalidad === '04') && (
                                            <div className='card-view-element'>
                                                {(compra.Modalidad == '04') ? 'Precio Recoleccion' : 'Precio por kg RECOLECTADO'}
                                                &nbsp;
                                                <TextField
                                                    required
                                                    id="codigo"
                                                    label="Obligatorio"
                                                    value={compra.PrecioRecolectado || ''}
                                                    className={compra.PrecioRecolectado ? "text-field" : 'text-field-empty'}
                                                    type="number"
                                                    step="0.00001" // Permitir hasta 6 decimales
                                                    name="PrecioRecolectado"
                                                    onChange={handleChange}
                                                />
                                                €
                                            </div>
                                        )}
                                        <div className='card-view-element '>
                                            <div>
                                                Sin Comision&nbsp;
                                                <Checkbox
                                                    value="checkedA"
                                                    checked={sinComision ? true : false}
                                                    inputProps={{
                                                        'aria-label': 'Checkbox A',
                                                    }}
                                                    onChange={sinComision ? () => setSinComision(false) : () => setSinComision(true)}
                                                /></div>
                                            Comisión:&nbsp;
                                            <TextField
                                                required
                                                id="codigo"
                                                label="Obligatorio"
                                                type="number"
                                                step="0.000001"
                                                disabled={sinComision ? (true) : false}
                                                className={sinComision ? 0 : compra.Comisiones ? "text-field" : 'text-field-empty'}
                                                name={"Comisiones"}
                                                value={sinComision ? 0 : (compra.Comisiones || '')}
                                                onChange={handleChange}
                                            />
                                            %
                                        </div>
                                        <div className='card-view-element '>
                                            Observaciones:&nbsp;
                                            <TextField
                                                required
                                                id="codigo"
                                                label="No Obligatorio"
                                                className={"text-field"}
                                                name={"Observaciones"}
                                                multiline
                                                rows={4}
                                                value={compra.Observaciones || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='card-view-element '>
                                            <div>A.N:&nbsp;
                                                <Checkbox
                                                    value="checkedA"
                                                    checked={anticipado ? true : false}
                                                    inputProps={{
                                                        'aria-label': 'Checkbox A',
                                                    }}
                                                    onChange={anticipado ? () => setAnticipado(false) : () => setAnticipado(true)}
                                                /></div>
                                            <div>{(anticipado === true) && (
                                                <div >
                                                    <TextField
                                                        required
                                                        id="codigo"
                                                        value={compra.Anticipo || ''}
                                                        className="text-field"
                                                        type="number"
                                                        step="0.00001" // Permitir hasta 6 decimales
                                                        name="Anticipo"
                                                        onChange={handleChange}
                                                    />
                                                    €
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='flex-container bottom-space'>
                                <Grid container spacing={6} justifyContent="center" >
                                    <Grid item xs={10} sm={6} md={3}>
                                        {(proveForm !== true) && (
                                            <div className='finalizar-compra-button' type="submit" onClick={handleSubmit}>
                                                Guardar Cambios
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>

    )
}
