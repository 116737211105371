import React, { useEffect, useState } from 'react'

import logo from '../../media/logo.png';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Header = () => {

	//global
	//util
	const [tokenusu, setTokenUsu] = useState('');

	//values
	const Navigate = useNavigate();
	useEffect(() => {
		setTokenUsu(localStorage.getItem('DesRepresentante'));
	}, [])


	return (
		<>
			<div className='header' onClick={() => Navigate("/")}>
				<div className='user-logged'>
				</div>
				<img src={logo} className='logo-header' />
				<div className='user-logged'>
					{tokenusu ? <AccountCircleIcon sx={{ fontSize: 40 }} /> : ''}&nbsp;<div>{tokenusu}</div>
				</div>
			</div>
		</>
	)
}
