import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfCompra } from '../global/PdfCompra';
import DrawIcon from '@mui/icons-material/Draw';
import axios from 'axios';
import Swal from 'sweetalert2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';

export const CompraCard = ({ compra, firmada, setCompras, compras }) => {

    //global 
    const Navigate = useNavigate();
    const wsUrl = "compras.clasol.com";

    const eliminarCompra = () => {
        Swal.fire({
            title: "Estas seguro/a?",
            text: "Vas a ELIMINAR permanentemente el contrato c" + compra.IDCompra + " !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#ff8d22',
            cancelButtonColor: '#gray',
            confirmButtonText: "SI, Eliminalo!",
            cancelButtonText: "No, no lo Elimines"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Eliminado!",
                    text: "El contrato c" + compra.IDCompra + " ha sido eliminado.",
                    icon: "success",
                    timer: 2000,
                    confirmButtonColor: '#ff8d22',
                });
                const temp = compras.filter(item => item.IDCompra !== compra.IDCompra);
                axios.delete('https://' + wsUrl + '/WS/comprasPost.php', {
                    data: { IDCompra: compra.IDCompra },
                })
                    .then(function (response) {
                        if (response.data.success) {
                            setCompras(temp);
                        }

                    })
                    .catch(function (error) {
                        console.error('Error al enviar la solicitud:', error);
                    });

            }
        });

    }

    return (
        <>
            <div className='compras-list-element'>
                Fecha: {compra.Fecha}
            </div>
            <div className='compras-list-element'>
                Representante: {compra.DesRepresentante}
            </div>
            <div className='compras-list-element'>
                Proveedor: {compra.Nombre} {compra.NIF}
            </div>
            <div className='compras-list-element'>
                Cantidad: {compra.Cantidad} Kg
            </div>
            <div className='compras-list-element'>
                Variedad: {compra.DesVariedad}
            </div>
            <div className='compras-list-element'>
                Codigo Compra: {compra.CodigoCompra ? compra.CodigoCompra : compra.IDCompra}
            </div>
            {(firmada === 'Firmar') && (
                <div className='compras-list-element'>
                    <div className='Cancel-form flex-container' onClick={() => Navigate("/ver-compra/" + compra.IDCompra + "")}>
                        <EditNoteIcon sx={{ fontSize: 30 }} className='icon' />EDITAR
                    </div>
                </div>
            )}
            <div className='compras-list-element'>
                <div className='Cancel-form flex-container' onClick={() => Navigate("/pdf-preview/" + compra.IDCompra + "")}>
                    &nbsp;{firmada === 'Firmar' ? <DrawIcon sx={{ fontSize: 30 }} className='icon'></DrawIcon> : <VisibilityIcon />}&nbsp;{firmada}
                </div>
            </div>
            {(firmada === 'Firmar') && (
                <div className='compras-list-element'>
                    <div className='Cancel-form flex-container' onClick={() => eliminarCompra()}>
                        <DeleteForeverIcon sx={{ fontSize: 30 }} className='icon' />Eliminar
                    </div>
                </div>
            )}

        </>
    )
}
