import logo from './logo.png';
import './App.css';
import Router from './router/Router';

function App() {
  return (
    <div>
		  <Router></Router>
    </div>
  );
}

export default App;
