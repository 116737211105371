import React, { useState } from 'react'
import { Header } from '../global/Header'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';


export const ComprasAica = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [browser, setBrowser] = useState('');
    const [compras, setCompras] = useState([]);

    const Navigate = useNavigate();
    //buscador
    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        const filteredData = browser.filter((item) =>
            searchTerm
                .split(/\s+/)
                .every((word) => item.lista.toLowerCase().includes(word))
        );

        setCompras(filteredData);
    };

    return (
        <div>
            <Header />
            <div className='flex-left'>
                <div className='return-button' onClick={() => Navigate("/")}>
                    <KeyboardReturnIcon />
                    &nbsp;Volver
                </div>
                <div className='flex-container'>
                    <div className='header-compras-list'>
                        Listado de compras Subidas al AICA
                    </div>
                </div>
                <div className='flex-container'>
                    <div className='browser'>
                        <div className='browser-card'>
                            <SearchIcon sx={{ fontSize: 40 }} /><TextField value={searchTerm} onChange={handleSearch} fullWidth label="Buscar" id="fullWidth" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
