import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';


export const AltaProveedor = ({ setProveFrom, setOpProveedores, setProveedores, proveedores, opProveedores }) => {

    const wsUrl = "compras.clasol.com";
    const [nuevoProveedor, setNuevoProveedor] = useState({
        "Nombre": null,
        "NIF": null,
        "CP": null,
        "Ciudad": null,
        "Provincia": null,
        "FormaPago": 'P30',
        "Regimpuestos": null,
        "Direccion": null,
        "Telefono": null

    })

    const [CPoptions, setCPoption] = useState([]);
    const [codigosPostales, setCodigosPostales] = useState([]);
    const [newCP, setNewCP] = useState('');

    const [formasPago, setFormasPago] = useState([]);
    const [selFormaPago, setSelFormaPago] = useState('');

    const [regImpuestos, setRegImpuestos] = useState([]);
    const [selRegImpuestos, setSelRegImpuestos] = useState('');


    useEffect(() => {
        firstLoad();
    }, []);

    const firstLoad = () => {

        //codigos postales
        axios.get('https://' + wsUrl + '/WS/codpostales.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setCodigosPostales(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.CP + " - " + item.Poblacion + " - " + item.Provincia,
                    value: item.ID
                }));
                setCPoption(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })

        axios.get('https://' + wsUrl + '/WS/formapago.php')
            .then(function (response) {
                // manejar respuesta exitosa
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.DesFormaPago + '-' + item.CodFormaPago,
                    value: item.CodFormaPago
                }));
                setFormasPago(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })

        axios.get('https://' + wsUrl + '/WS/regimpuestos.php')
            .then(function (response) {
                // manejar respuesta exitosa 
                setRegImpuestos(response.data);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })

    }


    const handleChange = (event) => {
        const nuevaCompra = { ...nuevoProveedor, [event.target.name]: event.target.value };
        setNuevoProveedor(nuevaCompra);
    }

    const uploadProveedor = () => {

        let cpInfo = {};
        let preparedProveedor = {
            "proveedor": {
                "Nombre": nuevoProveedor.Nombre,
                "NIF": nuevoProveedor.NIF,
                "CP": cpInfo.CP,
                "Poblacion": cpInfo.Poblacion,
                "Provincia": cpInfo.Provincia,
                "FormaPago": 'P30',
                "Regimpuestos": nuevoProveedor.Regimpuestos,
                "CodTercero": "001",
                "Direccion": "Test direction"
            }
        };
        if (newCP) {
            cpInfo = codigosPostales.find((cp => cp.ID === newCP.value))


            preparedProveedor = {
                "Nombre": nuevoProveedor.Nombre,
                "NIF": nuevoProveedor.NIF,
                "CP": cpInfo.CP,
                "Poblacion": cpInfo.Poblacion,
                "Provincia": cpInfo.Provincia,
                "FormaPago": 'P30',
                "RegImpuestos": nuevoProveedor.Regimpuestos,
                "CodTercero": "001",
                "Direccion": nuevoProveedor.Direccion,
                "Telefono": nuevoProveedor.Telefono
            }
        }

        const isNullInObject = Object.values(preparedProveedor).every(value => value !== null && value != '');

        if (!isNullInObject) {
            Swal.fire({
                icon: 'error',
                title: 'Revisa que los campos esten correctamente',
                showConfirmButton: false,
                timer: 2500
            })
        } else {

            // Realizar la solicitud POST utilizando Axios
            console.log(preparedProveedor)

            axios.post('https://' + wsUrl + '/WS/proveedorpost.php', preparedProveedor)
                .then(function (response) {
                    if (response.data.message) {
                        preparedProveedor.CodTercero = response.data.NumMax;
                        setOpProveedores([...opProveedores, {
                            label: preparedProveedor.Nombre,
                            value: preparedProveedor.CodTercero
                        }]);
                        setProveedores([...proveedores, preparedProveedor])
                        Swal.fire({
                            icon: 'success',
                            title: 'Proveedor añadido correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setProveFrom(false);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Revisa que todos los datos esten correctamente',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    }

                })
                .catch(function (error) {
                    console.error('Error al enviar la solicitud:', error);
                });
        }
    }

    return (
        <div className='proveedor-form'>
            <div className='card-view-body'>
                <div className='card-view-element'>
                    Nombre:
                    <TextField
                        className={nuevoProveedor.Nombre ? "text-field" : 'text-field-empty'}
                        required
                        id="codigo"
                        label="Obligatorio"
                        type='integer'
                        name={"Nombre"}
                        value={nuevoProveedor.Nombre || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className='card-view-element'>
                    NIF o CIF
                    <TextField
                        className={nuevoProveedor.NIF ? "text-field" : 'text-field-empty'}
                        required
                        id="codigo"
                        label="Obligatorio"
                        type='integer'
                        name={"NIF"}
                        value={nuevoProveedor.NIF || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className='card-view-element'>
                    DIRECCION:
                    <TextField
                        className={nuevoProveedor.Direccion ? "text-field" : 'text-field-empty'}
                        required
                        id="codigo"
                        label="Obligatorio"
                        type='text'
                        name={"Direccion"}
                        value={nuevoProveedor.Direccion || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className='card-view-element'>
                    TELEFONO:
                    <TextField
                        className={nuevoProveedor.Telefono ? "text-field" : 'text-field-empty'}
                        required
                        id="codigo"
                        label="Obligatorio"
                        type='text'
                        name={"Telefono"}
                        value={nuevoProveedor.Telefono || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className='card-view-element cp'>
                    Código Postal - Ciudad - Provincia:
                    <Autocomplete
                        className={newCP ? "inputDateInput cp" : 'inputDateInput-empty cp'}
                        disablePortal
                        id="combo-box-demo"
                        name="Proveedor"
                        options={CPoptions || ''}
                        sx={{ width: 300 }}
                        value={newCP}
                        onChange={(event, newValue) => {
                            setNewCP(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                    />
                </div>

                {/* <div className='card-view-element'>
                Forma  de Pago:
                <Autocomplete 
                    className={selFormaPago ? "inputDateInput cp" : 'inputDateInput-empty cp'}
                    disablePortal
                    id="combo-box-demo"
                    name="Proveedor"
                    options={formasPago}
                    sx={{ width: 300 }}
                    value={selFormaPago}                                       
                    onChange={(event, newValue) => {
                        setSelFormaPago(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                />
            </div> */}
                <div className='card-view-element'>
                    Regimen de impuestos:
                    <Select
                        className={nuevoProveedor.Regimpuestos ? "text-field" : 'text-field-empty'}
                        value={nuevoProveedor.Regimpuestos || ''}
                        name={"Regimpuestos"}
                        onChange={handleChange}
                        defaultValue=''
                    // onChange={handleChange}
                    >
                        {regImpuestos.map((regImpuesto, key) => (
                            <MenuItem
                                key={regImpuesto.DesRegImpuestos}
                                value={regImpuesto.CodRegImpuestos}
                                onChange={handleChange}
                            >
                                {regImpuesto.DesRegImpuestos}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className='buttons-container'>
                    <div className='Cancel-form' onClick={() => uploadProveedor()}>
                        Añadir proveedor
                    </div>
                    <div className='Cancel-form' onClick={() => setProveFrom(false)}>
                        <div>Cancelar</div>
                    </div>
                </div>

            </div>
        </div>
    )
}
