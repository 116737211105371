import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Input, Slider } from '@mui/material';
import Swal from 'sweetalert2';
import { useFetcher } from 'react-router-dom';


export const ProveedorCard = ({ setArrProveeCompra, arrProveeCompra, tmpProve, prov, idProveedorPrincipal, dissSldiers }) => {
	
	//global
	//util
	const [value, setValue] = useState();
	//values
	useEffect(() => {
		if (prov.porcentaje) {
			let toInt = prov.porcentaje;
			setValue(toInt);
		}
	}, [])

	const añadirArrProveedores = (id) => {
		if (id) {
			const tmp = arrProveeCompra.filter(prov => prov.value !== id);
			setArrProveeCompra(tmp);
		} else {
			if (arrProveeCompra.find(prov => prov.value === tmpProve.value)) {
				Swal.fire({
					icon: 'error',
					title: 'No puedes añadir un Proveedor que ya participa en esta compra',
					showConfirmButton: false,
					timer: 2500
				})
			} else if (tmpProve) {
				var tmpTmpProve = tmpProve;
				tmpTmpProve = { ...tmpTmpProve, porcentaje: 0 };
				var tmp = [...arrProveeCompra, tmpTmpProve];
				setArrProveeCompra(tmp);
			}

		}

	}

	///sliders


	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
		if (prov && typeof prov === 'object' && 'porcentaje' in prov) {
			prov.porcentaje = newValue;
		}

	};

	const handleInputChange = (event) => {
		const newValue = event.target.value === '' ? 0 : parseFloat(event.target.value);
		setValue(newValue);
		if (prov && typeof prov === 'object' && 'porcentaje' in prov) {
			prov.porcentaje = newValue;
		}
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 100) {
			setValue(100);
		} else if (value !== undefined) {

			setValue(parseFloat(value.toFixed(2)));
			if (prov && typeof prov === 'object' && 'porcentaje' in prov) {
				prov.porcentaje = parseFloat(value.toFixed(2));
			} // Redondea el valor a 2 decimales
		}
	};


	///end sliders

	return (
		<div className='proveedor-element' id={prov.value}>
			<div >
				{prov.label}
			</div>
			{(idProveedorPrincipal !== prov.value && !dissSldiers) && (
				<div className='add-button' onClick={() => añadirArrProveedores(prov.value)}>
					<HighlightOffIcon />
				</div>
			)}

			<Slider
				value={typeof value === 'number' ? value : 0}
				onChange={handleSliderChange}
				aria-labelledby="decimal-slider"
				defaultValue={0}
				step={0.1}
				disabled={dissSldiers ? true : false}
			/>
			<div>
				<Input
					disabled={dissSldiers ? true : false}s
					value={value}
					size="small"
					onChange={handleInputChange}
					onBlur={handleBlur}
					defaultValue={0}
					inputProps={{
						step: 0.1,
						min: 0,
						max: 100,
						type: 'number',
						'aria-labelledby': 'input-slider',
					}}
				/>%
			</div>

		</div>
	)
}
