import React, { useEffect, useState, useRef } from 'react';
import { Page, Text, Image, Document, View, StyleSheet, PDFViewer, PDFDownloadLink, Canvas } from '@react-pdf/renderer';
import axios from 'axios';
import logo from '../../media/logoVertical.png';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfCompra } from './PdfCompra';
import SignatureCanvas from 'react-signature-canvas'
import { Header } from './Header';
import Swal from 'sweetalert2';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getCookie } from '../utils/cookies';

export const CompraViwerPDF = () => {

    //global 
    const param = useParams();
    const wsUrl = "compras.clasol.com";
    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');

    //value
    const [first, setfirst] = useState("second");
    const [loaded, setLoaded] = useState(false);
    const [representantes, setRepresentantes] = useState([]);
    const [representante, setRepresentante] = useState('');
    const [variedades, setVariedades] = useState([]);
    const [proveedoresCompra, setProveedoresCompra] = useState([]);
    const [variedad, setVariedad] = useState("");
    const [opVariedades, setOpVariedades] = useState([]);
    const [modalidad, setModalidad] = useState([]);
    const [ejercicio, setEjercicio] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState("");
    const [opProvloaded, setOpProvLoaded] = useState(false);
    const [data, setData] = useState({ nombre: "prueba " });
    const [mailProveedor, setMailProveedor] = useState('');
    const [telefono, setTelefono] = useState('');
    const [tokenMail, setTokenMail] = useState('');

    ///firmas
    const [firmaCompradorImg, setFirmaCompradorImg] = useState('');
    const [firmaCorredorrImg, setFirmaCorredorImg] = useState('');
    const [firmaVendedorImg, setFirmaVendedorImg] = useState('');


    //util 
    const [compra, setCompra] = useState({
        'Ejercicio': null,
        'CodigoCompra': null,
        'Fecha': "2023-01-01",
        'Proveedor': null,
        'Referencia': null,
        'FormaPago': null,
        'RegImpuestos': null,
        'Comprador': null,
        'Fecha1Recoleccion': null,
        'Representante': null,
        'DesRepresentante': null,
        'Variedad': null,
        'Modalidad': null,
        'Cantidad': null,
        'Precio': null,
        'Comisiones': null,
        'Observaciones': null,
        'FirmaVendedor': '',
        'FirmaComprador': '',
        'FirmaCorredor': '',
        'mail': mailProveedor,
        'NombreProveedor': '',
        'NIF': '',
        'Direccion': '',
        'Poblacion': '',
        'CP': '',
        'Telefono': telefono,
        'Modalidad': '',
        'MailRepresentante': tokenMail,
        'MailAux': '',
        'Firmada': '',
        'ivaPrecio': false,
        'Provincia': ''
    });

    const firmaVendedor = useRef({});
    const firmaComprador = useRef({});
    const firmaCorredor = useRef({});


    //funciones
    const limpiar = () => {
        firmaVendedor.current.clear();
        firmaComprador.current.clear();
        firmaCorredor.current.clear();
    }

    const enviarMail = (email) => {

        //guardar
        if (compra.Firmada === '1') {
            compra.FirmaVendedor = firmaVendedorImg;
            compra.FirmaComprador = firmaCompradorImg;
            compra.FirmaCorredor = firmaCorredorrImg;
        } else {
            const firma = (firmaVendedor.current.getTrimmedCanvas().toDataURL("image/png"));
            compra.FirmaVendedor = firma;
            const firmac = (firmaComprador.current.getTrimmedCanvas().toDataURL("image/png"));
            compra.FirmaComprador = firmac;
            const firmacr = (firmaCorredor.current.getTrimmedCanvas().toDataURL("image/png"));
            compra.FirmaCorredor = firmacr;
        }
        if ((compra.FirmaComprador.length > 900 || compra.FirmaCorredor.length > 900) && compra.FirmaVendedor.length > 900) {
            //preparamos el objeto para enviarlo
            compra.mail = mailProveedor;
            compra.Telefono = telefono;
            compra.NombreProveedor = proveedor.Nombre;
            compra.Variedad = variedad.label;
            compra.NIF = proveedor.NIF;
            compra.Direccion = proveedor.Direccion;
            compra.Provincia = proveedor.Provincia;
            compra.Poblacion = proveedor.Ciudad;
            compra.ProveedoresComra = proveedoresCompra;
            compra.CP = proveedor.CP;
            compra.MailRepresentante = tokenMail
            compra.DesRepresentante = compra.Representante.DesRepresentante;
            if (email !== undefined) {
                compra.MailAux = email;
            } else {
                compra.MailAux = '';
            }

            axios.post('https://' + wsUrl + '/WS/pdfMail.php', compra)
                .then(function (response) {
                    console.log("s", compra);
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Enviado correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        Navigate("/ver-firmadas")
                    } else {
                        console.log(response)
                        Swal.fire({
                            icon: 'error',
                            title: 'Error en la solicitud. \nRevisa que los campos esten correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
                .catch(function (error) {
                    console.error('Error al enviar la solicitud:', error);
                });

        } else {
            Swal.fire({
                icon: "error",
                title: (`Son obligatorias todas las firmas`),
                showConfirmButton: false,
                timer: 2500
            });
        }



    }

    const alertCorreo = () => {
        (async () => {
            let email = "";
            let phone = "";
            while (email === "" || phone === "") {
                const { value: enteredEmail, value2: enteredPhone, isConfirmed } = await Swal.fire({
                    title: "Introduce el email y teléfono del proveedor",
                    icon: "warning",
                    html:
                        '<input id="swal-input1" class="swal2-input" placeholder="Email">' +
                        '<input id="swal-input2" class="swal2-input" placeholder="Teléfono">',
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: '#ff8d22',
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    preConfirm: () => {
                        return [
                            document.getElementById('swal-input1').value,
                            document.getElementById('swal-input2').value
                        ];
                    }
                });

                if (isConfirmed) {
                    email = enteredEmail;
                    phone = enteredPhone;

                    if (email === "" || phone === "") {
                        Swal.showValidationMessage("Por favor, introduce tu correo electrónico y teléfono");
                    }
                } else {
                    return;
                }
            }
            // Guardar email y teléfono
            console.log("Email:", email);
            console.log("Teléfono:", phone);

            // const nuevaCompra = {
            //     ...compra,
            //     Mail: email,
            // }
            Swal.fire({
                icon: "success",
                title: (`El contrato se enviara a la direccion de email: ${email}`),
                showConfirmButton: false,
                timer: 2500
            });
            setMailProveedor(email[0]);
            setTelefono(email[1])
            // enviarMail(phone);
        })();
    }

    ////cargado de datos
    useEffect(() => {
        setfirst(param.id);
        firstData();
        setTokenMail(localStorage.getItem('mail'));
    }, [])

    useEffect(() => {
        if (representantes.length > 0 && proveedores.length > 0) {
            cargarDatosCompra();
        }
    }, [representantes, proveedores]);

    const formarProveedores = (prove) => {
        console.log(prove);
        let arrtemp = [];
        prove.map((pro, index) => {
            let tmp = proveedores.find(prov => prov.CodTercero === pro.CodTercero);

            // Si tmp no es undefined, significa que se encontró un proveedor con el mismo CodTercero
            if (tmp) {
                Object.assign(pro, tmp);
                if (pro.PorcenPropi !== undefined) {
                    pro.PorcenPropi = parseFloat(pro.PorcenPropi).toFixed(2);
                }
                arrtemp.push(pro);
                console.log(arrtemp);
            }
        });
        return arrtemp;
    };

    const firstData = () => {
        // Array de promesas para cada solicitud axios
        const axiosPromises = [
            axios.get('https://' + wsUrl + '/WS/representante.php'),
            axios.get('https://' + wsUrl + '/WS/variedades.php'),
            axios.get('https://' + wsUrl + '/WS/modalidad.php'),
            axios.get('https://' + wsUrl + '/WS/ejercicio.php'),
            axios.get('https://' + wsUrl + '/WS/proveedor.php?contrasena=sakmascna0o898ajhdasdj232')
        ];

        // Utilizar Promise.all para esperar a que todas las promesas se resuelvan
        Promise.all(axiosPromises)
            .then(function (responses) {
                // manejar respuesta exitosa para cada solicitud axios
                const representantesResponse = responses[0];
                setRepresentantes(representantesResponse.data);

                const variedadesResponse = responses[1];
                setVariedades(variedadesResponse.data);
                const temp = [...variedadesResponse.data];
                const newTemp = temp.map((item) => ({
                    label: item.DesVariedad,
                    value: item.CodVariedad
                }));
                setOpVariedades(newTemp);

                const modalidadResponse = responses[2];
                setModalidad(modalidadResponse.data);

                const ejercicioResponse = responses[3];
                const ejercicio = ejercicioResponse.data.find((element) => element.Activo == 1);
                setEjercicio(ejercicio);

                const nuevaCompra = { ...compra, "Ejercicio": ejercicio.Ejercicio };
                setCompra(nuevaCompra);

                const proveedoresResponse = responses[4];
                setProveedores(proveedoresResponse.data);
                setOpProvLoaded(true);
                // Aquí estableces la variable cuando todas las solicitudes se completen

            })
            .catch(function (errors) {
                // manejar error para alguna de las solicitudes axios
                console.log(errors);
            })
            .finally(function () {
            });
    };
    const reenviar = () => {
        (async () => {
            let email = "";
            while (email === "") {
                const { value: enteredEmail, isConfirmed } = await Swal.fire({
                    title: "Introduce el email al que queires reenviar este contrato",
                    icon: "warning",
                    input: "email",
                    inputPlaceholder: "Introduce el email al que quieres reenviar el contrato",
                    showCancelButton: true,
                    confirmButtonColor: '#ff8d22',
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                });

                if (isConfirmed) {
                    email = enteredEmail;
                    if (email === "") {
                        Swal.showValidationMessage("Please enter your email address");
                    } else {

                    }

                } else {
                    Navigate("/ver-compras")
                    return;
                }
            }
            Swal.fire({
                icon: "success",
                title: (`El contrato se enviara a la direccion de email: ${email}`),
                showConfirmButton: false,
                timer: 2500
            });
            enviarMail();
        })();
    }

    const cargarDatosCompra = () => {
        //    //datos de la compra especifica
        axios.get('https://' + wsUrl + '/WS/compras.php?id=' + param.id + '&firmada=0?&token=' + token)
            .then(function (response) {
                //           // manejar respuesta exitosa
                // console.log(response.data)
                const nuevaCompra = {
                    ...compra,
                    Cantidad: response.data[0].Cantidad,
                    Referencia: response.data[0].Referencia,
                    Fecha: response.data[0].Fecha,
                    Fecha1Recoleccion: response.data[0].Fecha1Recoleccion,
                    Precio: parseFloat(response.data[0].Precio),
                    Comisiones: response.data[0].Comisiones,
                    idCompra: response.data[0].IDCompra,
                    CodigoCompra: response.data[0].CodigoCompra ? response.data[0].CodigoCompra : response.data[0].IDCompra,
                    Modalidad: (modalidad.find(op => op.CodModalidad === response.data[0].Modalidad)),
                    Representante: (representantes.find(op => op.CodReferencia === response.data[0].Representante)),
                    Ejercicio: response.data[0].Ejercicio,
                    PrecioKg: ((response.data[0].Cantidad * response.data[0].Precio).toFixed(4)),
                    RegImpuestos: response.data[0].RegImpuestos,
                    Observaciones: response.data[0].Observaciones,
                    Firmada: response.data[0].Firmada,
                    Anticipo: response.data[0].Anticipo,
                    PrecioRecolectado: parseFloat(response.data[0].PrecioRecolectado),
                    ivaPrecio: response.data[0].sinIVA,

                };
                if (response.data[0].Firmada === '1') {
                    setFirmaCompradorImg(response.data[0].FirmaComprador);
                    setFirmaCorredorImg(response.data[0].FirmaCorredor);
                    setFirmaVendedorImg(response.data[0].FirmaVendedor);
                    setMailProveedor(response.data[0].Mail);
                    setTelefono(response.data[0].Telefono);

                } else {
                    alertCorreo();
                }

                setCompra(nuevaCompra);
                // console.log(nuevaCompra)
                setProveedor(proveedores.find(op => op.CodTercero === response.data[0].Proveedor[0].CodTercero));
                setProveedoresCompra(formarProveedores(response.data[0].Proveedor));
                setVariedad(opVariedades.find(op => op.value === response.data[0].Variedad));
                setLoaded(true);
                setRepresentante((representantes.find(op => op.CodReferencia === response.data[0].Representante)))
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            })
    }

    return (
        <div>
            <Header />
            <div className='flex-between'>
                <div className='return-button' onClick={() => Navigate("/")}>
                    <KeyboardReturnIcon />
                    &nbsp;Volver
                </div>
                {(compra.Firmada === "1") && (
                    <div className='return-button' onClick={reenviar}>
                        <MailOutlineIcon />
                        &nbsp;Reenviar
                    </div>
                )}

            </div>
            <div className='flex-container ' >
                {(loaded === false || !proveedor) && (
                    <div className='flex-container spinner'>
                        <div className='loading-spinner'>
                        </div>
                    </div>
                )}
            </div>
            {(loaded !== false && proveedor) && (
                <>
                    <div className="flex-container">
                        <div size="A4">
                            <div className="A4" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",

                            }}>
                                <div style={{

                                    backgroundColor: "white",
                                    maxWidth: "90%",
                                    minWidth: "90%",
                                    // minHeight: "90vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <div style={{
                                        backgroundColor: "white",
                                        maxWidth: "90%",
                                        minWidth: "90%",

                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <img src={logo} className='logo-header' style={{ maxHeight: "8em" }} />
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "right", alignItems: "center", fontSize: "14px", margin: "2em" }}>
                                                    <div>Camino Sant Francesc. s/n </div>
                                                    <div>C.P. 12549 Betxí /Castellón </div>
                                                    <div>NIF 12654596</div>
                                                    <div>Tel. 964 62 30 00</div>
                                                    <div>Fax. 964 62 31 44</div>
                                                    <div>smartinez@sanalfonso.es</div>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "white",
                                            }}>
                                                <div style={{ fontSize: "18px", fontWeight: 500 }}> CONTRATO DE COMPRAVENTA</div>
                                                <div style={{ margin: "0.1em", fontWeight: 500 }}>
                                                    Nº de contrato: C {compra.CodigoCompra}
                                                </div>
                                                <div style={{ margin: "0.1em", fontWeight: 500 }}>
                                                    En Betxí, a {(compra.Fecha).substring(8, 10)} del {(compra.Fecha).substring(5, 7)}  de {(compra.Fecha).substring(0, 4)}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: "0.1em", fontWeight: 500 }}>
                                            Identificación de las partes contrarias:
                                        </div>
                                        <div style={{ margin: "0.5em 0em", fontWeight: 500 }}>
                                            {proveedoresCompra.length > 1 && (
                                                <>
                                                    <strong>De una parte: <br></br>
                                                        {proveedoresCompra.map(provComp => (
                                                            <>
                                                                <strong> {(provComp.Nombre)}&nbsp;D.N.I&nbsp;{(provComp.NIF)} con un {(provComp.PorcenPropi)}% de de participacion en el contrato</strong>&nbsp;
                                                                <strong>Dirección</strong> {(proveedor.Direccion)}&nbsp;
                                                                <strong>Población</strong> {(proveedor.Ciudad)} <strong>, C.P.</strong> {(proveedor.CP)}&nbsp;
                                                                <strong>PROVINCIA</strong> {(proveedor.Provincia)}
                                                            </>
                                                        ))}
                                                    </strong>
                                                </>
                                            )}
                                            {proveedoresCompra.length <= 1 && (
                                                <>
                                                    <strong>
                                                        De una parte,{(proveedor.Nombre)}D.N.I&nbsp;{(proveedor.NIF)}  (en adelante, el "Vendedor")<br></br>
                                                        Dirección {(proveedor.Direccion)}&nbsp;
                                                        Población {(proveedor.Ciudad)} <strong>, C.P.</strong> {(proveedor.CP)}
                                                        PROVINCIA {(proveedor.Provincia)}
                                                    </strong>
                                                </>
                                            )}

                                        </div>
                                        <div style={{ margin: "0.5em 0em", fontWeight: 500 }}>
                                            Tel. {(telefono) ? (telefono) : '___________________'}&nbsp; e-mail. {(mailProveedor) ? (mailProveedor) : '__________________________________'}
                                        </div>
                                        <div style={{ margin: "0.5em 0em", fontWeight: 500 }}>
                                            <strong>Y de otra parte, CLASOL COOP. V.,</strong> con domicilio social en Betxí (Castellón), Camino Sant Francesc s/n, C.P. 12549 con NIF F12654596,
                                            representada en este acto por <strong>{representante.DesRepresentante ? representante.DesRepresentante : '__________________'}&nbsp; </strong>(en adelante, el "Comprador").
                                            Las partes acuerdan suscribir el presente contrato (en adelante, el "Contrato") que se regirá por las siguientes CLÁUSULAS:
                                        </div>
                                        <div>
                                            <strong>1. Objeto</strong>
                                        </div>
                                        <div>
                                            El Vendedor vende al Comprador, que compra, en los términos y condiciones establecidos en el presente Contrato, la cantidad aproximada de {compra.Cantidad} KG kilos(en peso)
                                            de los productos de la vairedad {variedad.label}, de la partida o huerto {compra.Referencia} (en adelante, el "Producto").
                                            La modalidad de compra es {compra.Modalidad.DesModalidad}&nbsp;.
                                        </div>
                                        <div>
                                            <strong>2. Precio</strong>
                                        </div>
                                        {(compra.Modalidad.CodModalidad === "01") && (
                                            <div>
                                                El comprador abonará al vendedor una cantidad que se corresponderá con la diferencia entre el ingreso obtenido por la ventana del
                                                grupo varietal en el que se haya incluido  el Producto para su venta y los gastos incurridos por el comprador en la venta del mismo
                                                grupo varietal varietal (incluidos entre otros los gastos de escandallo, confección, transporte y comisiones pagadas a intermediarios
                                                en dicha venta), a prorrata de los kilos recolectados que comprenden el Producto. Los ingresos y los gastos en cada grupo varietal
                                                podrán venir determinados tanto por la evolución de la situación del mercado y el volumen entregado como por la calidad, el calibre y
                                                las fechas de recolección del Producto. Se acompaña como anexo a este contrato una tabla con los grupos varietales de venta existente
                                                a fecha de hoy.
                                            </div>
                                        )}
                                        {(compra.Modalidad.CodModalidad === "02") && (
                                            <div>
                                                (Precio Fijo): El precio de la totalidad de los Kilos recolectados será de <strong>{compra.Precio} Euros {compra.ivaPrecio ? 'más IVA' : 'IVA incluido'}</strong>
                                            </div>
                                        )}
                                        {(compra.Modalidad.CodModalidad === "03") && (
                                            <div>
                                                (Precio Fijo): El precio del producto es de {compra.Precio} Euro / kg, {compra.ivaPrecio ? 'más IVA' : 'IVA incluido'}. {(compra.Comisiones > 0) && (
                                                    <div>
                                                        Se aplicará un descuento del {compra.Comisiones}% sobre el importe del precio IVA incluido( es decir, sobre la base imponible de la fruta) en un
                                                        concepto de merma.
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {(compra.Modalidad.CodModalidad === "04") && (
                                            <div>
                                                (Comercial Precio): El precio total del producto es de {(compra.Precio + compra.PrecioRecolectado)} Euro / KG, {compra.ivaPrecio ? 'más IVA' : 'IVA incluido'}. ({compra.Precio} €/kg Fruta + {compra.PrecioRecolectado} €/kg otros conceptos ) {(compra.Comisiones > 0) && (
                                                    <div>
                                                        Se aplicará un descuento del {compra.Comisiones}% sobre el importe del precio 'IVA incluido' ( es decir, sobre la base imponible de la fruta) en un
                                                        concepto de merma.
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {(compra.Modalidad.CodModalidad === "06") && (
                                            <div>
                                                (Precio fijo + variable), El precio fijo del Producto es de {compra.Precio} Euro / kg, {compra.ivaPrecio ? 'más IVA' : 'IVA incluido'}. El comprador abonará
                                                al vendedor una cantidad que se corresponderá con la diferencia entre el ingreso obtenido por la ventana del grupo varietal
                                                en el que se haya incluido  el Producto para su venta y los gastos incurridos por el comprador en la venta del mismo grupo varietal
                                                incluidos en el que se haya incluido en el Producto para su venta y los gastos incurridos por el Comprador en la venta del mismo
                                                grupo varietal incluidos entre otros los gastos de escandallo, confección, transporte y comisiones pagadas a intermediarios
                                                en dicha venta, a prorrata de los kilos recolectados que comprenden el Producto. El importe así obtenido será a su vez minorado
                                                en el precio fijo mínimo ya abonado al Vendedor (precio variable) Los ingresos y los gastos en cada grupo varietal podrán venir
                                                determinados tanto por la evolución de la situación del mercado y el volumen entregado como por la calidad, el calibre y las fechas
                                                de recolección del Producto. Se acompaña como anexo a este contrato una tabla con los grupos varietales de venta existente a fecha
                                                de hoy.
                                            </div>
                                        )}

                                        <div>
                                            <strong>3. Fecha y lugar de entrega y puesta a disposicion del producto.</strong>
                                        </div>
                                        {compra.Fecha1Recoleccion && compra.Fecha1Recoleccion !== "0000-00-00" && (
                                            <div>
                                                Fecha límite de entrega del Producto: {compra.Fecha1Recoleccion}
                                            </div>
                                        )}

                                        <div>
                                            Lugar de entrega del Producto: Betxí, Castellón. Identidad del recolector destino del Comprador.
                                        </div>
                                        <div>
                                            <strong>4. Obligaciones del Vendedor.</strong>
                                        </div>
                                        <div>
                                            4.1 El Vendedor se compromete a cultivar según uso y costumbre de buen agricultor.<br></br>
                                            4.2 El Vendedor se compromete a utilizar exclusivamente materiales autorizados por la legalidad vigente para cítricos y con los límites impuestos por la misma.<br></br>
                                            4.3 El vendedor se compromete a respetar los plazos máximos de aplicacion de las materias activas empleadas que establece la legislación que resulte de aplicación en cada caso.<br></br>
                                            4.4 El Vendedor se olbiga a cumplir las limitaciones que se indiquen por la Cooperativa en la lista de productos recomendados para cada campaña, que se publica en Ministerio de Agricultura.<br></br>
                                            4.5 El Vendedor acepta que el Comprador realice, con cargo al Vendedor, un análisis del producto con caracter previo a la recolección a fin de verificar el cumplimiento de las anteriores obligaciones.<br></br>
                                        </div>
                                        <div>
                                            <strong>5. Información comercial.</strong>
                                        </div>
                                        <div>
                                            Las partes  están de acuerdo en que no se va a intercambiar información comercial sensible en el sentido de la ley 16/2021, de 14 de diciembre, por la que se modifica la ley 1272013, de 2 agosto,
                                            de medidas para mejorar el funcionamiento de la cadena alimentaria.
                                        </div>
                                        {(compra.Anticipo > 0) && (
                                            <div>
                                                <strong>Anticipo: </strong>El anticipo será de {compra.Anticipo} Euros.
                                            </div>
                                        )}

                                        <div>
                                            <strong>Observaciones:</strong>
                                        </div>
                                        <div>
                                            <p>{compra.Observaciones}</p>
                                        </div>
                                        <div style={{ minHeight: "2vh" }}>
                                        </div>
                                        <div>
                                            Y en prueba de conformidad con cuanto antecede, las partes firman este Contrato, que incluye las condiciones generales del reverso y todos los anexos, en tantos ejemplares como itervinientes hay en el Contrato, y a
                                            un solo efecto en el lugar y fecha indicados en el encabezamiento.
                                        </div>
                                        <div style={{ minHeight: "2vh" }}>
                                        </div>
                                        {(compra.Firmada !== '1') && (
                                            <>
                                                <div style={{ minHeight: "10vh", display: "flex", justifyContent: "space-evenly" }}>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Vendedor</strong>
                                                        <SignatureCanvas ref={firmaVendedor} canvasProps={{ width: 200, height: 200, className: 'sigCanvas' }} />
                                                    </div>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Comprador</strong>
                                                        <SignatureCanvas ref={firmaComprador} canvasProps={{ width: 200, height: 200, className: 'sigCanvas' }} />
                                                    </div>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Corredor(en su caso)</strong>
                                                        <SignatureCanvas ref={firmaCorredor} canvasProps={{ width: 200, height: 200, className: 'sigCanvas' }} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {(compra.Firmada === '1') && (
                                            <>
                                                <div style={{ minHeight: "10vh", display: "flex", justifyContent: "space-evenly" }}>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Vendedor</strong>
                                                        <img className="firma-img" src={firmaVendedorImg}></img>
                                                    </div>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Comprador</strong>.
                                                        <img className="firma-img" src={firmaCompradorImg}></img>

                                                    </div>
                                                    <div style={{ margin: "1em", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                                        <strong>D./Dña Corredor(en su caso)</strong>
                                                        <img className="firma-img" src={firmaCorredorrImg}></img>

                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/* final folio	 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='compras-list-element'> */}
                    {(compra.Firmada !== '1') && (
                        <div className='buttons-container'>
                            <div className='firmar-button' onClick={() => limpiar()}>
                                Limpiar firmas
                            </div>
                            {/* <PDFDownloadLink
                            document={<PdfCompra compra={param.id} />}
                            fileName="Compravneta.pdf">
                            <div className='firmar-button' onClick={() => enviarMail()}>
D                            </div>
                        </PDFDownloadLink> */}
                            <div className='firmar-button' onClick={() => enviarMail()}>
                                Enviar
                            </div>
                        </div>
                    )}
                    <div className='botton-space'>&nbsp;</div>
                    {/* </div> */}
                </>
            )}
        </div>
    )
}
